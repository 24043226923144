import React, { FC } from "react";
import { IJob } from "../../../core/interfaces/job.interface";
import "../index.scss";

interface IProps {
    job: IJob | undefined;
}

const JobDetail: FC<IProps> = (props) => {
    const { job } = props;

    return (
        <>
            {job && (
                <div className="job__detail__container">
                    <p className="job__detail__title">{job.title}</p>
                    <div className="job__detail__paragraph">
                        <p className="job__detail__paragraph__title">
                            Job Description
                        </p>
                        <p>{job.description}</p>
                    </div>
                    <div className="job__detail__paragraph">
                        <p className="job__detail__paragraph__title">
                            Required Minimums
                        </p>
                        <p>{job.qualifications}</p>
                    </div>
                </div>
            )}
        </>
    );
};

export default JobDetail;
