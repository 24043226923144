import React, { useEffect } from "react";
import Glide from "@glidejs/glide";
import danSousaImage from "./../../assets/images/dan-sousa.png";

const CustomersCarousel = () => {
    useEffect(() => {
        new Glide(".glide", {
            type: "carousel",
            autoplay: 5000,
        }).mount();
    }, []);
    return (
        <div className={"bg-fu-gray relative"}>
            <div
                className="glide"
                style={{
                    minHeight: 250,
                }}
            >
                <div className="glide__track" data-glide-el="track">
                    <ul className="glide__slides">
                        <li className="glide__slide">
                            <div className={"flex flex-col md:flex-row px-10 md:px-20 py-6 gap-4"}>
                                <div className={"w-1/4 relative h-32 md:h-auto mx-auto"}>
                                    <div
                                        className="w-full h-full absolute top-0 left-0 bg-cover bg-center"
                                        style={{
                                            backgroundImage: `url(${danSousaImage})`,
                                        }}
                                    ></div>
                                </div>
                                <div className={"text-white space-y-4 w-full md:w-3/4 text-center md:text-left"}>
                                    <div className={"font-ABeeZee italic text-sm"}>
                                        “Finding skilled labor or craftsmen is a skill in itself. Fillerup Employment has striven to fill this need
                                        for contractors, and as one on the receiving end, I have been very appreciative. Fillerup provides their
                                        employees who show true interest opportunities to further their skills and education with classes such as
                                        OSHA, forklift training, scissor lift training, CPR, basic carpentry and more. I am grateful for the help they
                                        provide both for my project success and for more importantly for the personal lives they aid and empower.”
                                    </div>
                                    <div className={"font-Abel uppercase tracking-wide"}>
                                        Dan Sousa
                                        <br />
                                        Okland
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="glide__slide">
                            <div className={"flex px-10 md:px-20 py-6 gap-4 items-center"}>
                                <div className={"text-white text-center space-y-4"}>
                                    <div className={"font-ABeeZee italic text-sm"}>
                                        “We enjoy working with your guys, they bring a lot of value and integrity to the project. They are always
                                        prepared and wearing their PPEs. Fillerup is our preferred provider when it comes to skilled labor. Our
                                        experience with your foreman has been a great one, you can give a list of things and they go out there and get
                                        it done. They have the project interest in mind and they are on board with the values and the safety culture
                                        of the company.”
                                    </div>
                                    <div className={"font-Abel uppercase tracking-wide"}>
                                        Matt Tuttle
                                        <br /> Layton Construction
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="glide__slide">
                            <div className={"flex px-10 md:px-20 py-6 gap-4 items-center"}>
                                <div className={"text-white text-center space-y-4"}>
                                    <div className={"font-ABeeZee italic text-sm"}>
                                        “Fillerup’s guys are always here to do everything that we ask. They follow safety procedures through our
                                        safety meetings every morning, job hazard analysis, pre-task. Fillerup guys are always willing to stay late if
                                        we need to, they always have good attitudes on doing different projects even though sometimes we get pulled in
                                        different directions. Fillerup has a large pool of guys with specific skills to choose from, most of the help
                                        we get is always on time, reliable and when we are in a bind Fillerup has always come through in getting us
                                        the help we need.”
                                    </div>
                                    <div className={"font-Abel uppercase tracking-wide"}>
                                        Ed
                                        <br />
                                        Layton
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

                <div className="glide__bullets" data-glide-el="controls[nav]">
                    <button className="glide__bullet" data-glide-dir="=0"></button>
                    <button className="glide__bullet" data-glide-dir="=1"></button>
                    <button className="glide__bullet" data-glide-dir="=2"></button>
                </div>
            </div>
        </div>
    );
};

export default CustomersCarousel;
