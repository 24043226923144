import gql from "graphql-tag";
import { JobFragment } from "../fragments/job.fragment";

export const GET_JOBS = gql`
    query Jobs($input: JobPagingInput) {
        Jobs(input: $input) {
            data {
                ...Job
            }
        }
    }
    ${JobFragment}
`;
