import React, { FC } from "react";
import { IJob } from "../../../core/interfaces/job.interface";

interface IProps {
    jobs: IJob[];
}

const AllPositions: FC<IProps> = (props) => {
    const { jobs } = props;
    return (
        <div>
            <p className="all__positions__title">
                All Fillerup positions are listed below. Check back often to see
                what positions are actively hiring on our Hiring Now page.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-3  gap-5">
                {jobs.map((job) => (
                    <div key={job.id} className="all__positions__item">
                        {job.title}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AllPositions;
