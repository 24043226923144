import { gql } from "@apollo/client";

export const JobFragment = gql`
    fragment Job on Job {
        id
        title
        hiring
        activeSince
        workingDay
        incomeType
        incomeAmount
        shift
        description
        qualifications
        createdAt
        updatedAt
    }
`;
