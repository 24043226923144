import React, { FC } from "react";
import { IJob } from "../../../core/interfaces/job.interface";
import briefcaseLogo from "../../../assets/icons/briefcase.icon.svg";
import moneyLogo from "../../../assets/icons/money.icon.svg";
import timeLogo from "../../../assets/icons/time.icon.svg";
import "../index.scss";

interface IProps {
    job: IJob;
    jobSelected: IJob | undefined;
    onClick: () => void;
    onApply: () => void;
}

const JobCard: FC<IProps> = (props) => {
    const { job, jobSelected, onClick, onApply } = props;

    const getWorkingDay = (): string => {
        if (job.workingDay === "FULL_TIME") return "Full-time";
        else return "Part-time";
    };

    const getShift = (): string => {
        switch (job.shift) {
            case "DAY":
                return "Day Shift";
            case "NIGHT":
                return "Night Shift";
            case "SPLIT":
                return "Split Shift";
            case "SWING":
                return "Swing Shift";
            default:
                return "";
        }
    };

    const getIncomeAmount = (): string => {
        return `$${job.incomeAmount?.toLocaleString()} ${job.incomeType?.toLowerCase()}`;
    };

    return (
        <div
            onClick={onClick}
            className={`${
                job.id === jobSelected?.id && "active__jobcard"
            } job__card`}
        >
            <p className="job__card__title">{job.title}</p>
            <div className="job__card__items__container">
                <div className="job__card__item">
                    <div>
                        <img
                            className="icon"
                            src={briefcaseLogo}
                            alt="briefcase icon"
                        />
                    </div>
                    <div>{getWorkingDay()}</div>
                </div>
                <div className="job__card__item">
                    <div>
                        <img className="icon" src={timeLogo} alt="time icon" />
                    </div>
                    <div>{getShift()}</div>
                </div>
                <div className="job__card__item">
                    <div>
                        <img
                            className="icon"
                            src={moneyLogo}
                            alt="money icon"
                        />
                    </div>
                    <div>{getIncomeAmount()}</div>
                </div>
            </div>
            <button
                onClick={() => onApply()}
                className="button__primary job__card__button"
            >
                Apply Now
            </button>
        </div>
    );
};

export default JobCard;
