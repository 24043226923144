import gql from "graphql-tag";
import { ApplicantFragment } from "../fragments/applicant.fragment";

export const CREATE_APPLICANT = gql`
    mutation ApplicantCreate($input: ApplicantCreate!) {
        ApplicantCreate(input: $input) {
            ...Applicant
        }
    }
    ${ApplicantFragment}
`;
