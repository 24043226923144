import React from "react";
import "./App.scss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Modal from "react-modal";
import Footer from "./core/components/Footer";
import Header from "./core/components/Header";
import ScrollToTop from "./core/components/ScrollToTop";
import ApplicantsPage from "./pages/applicants";
import HomePage from "./pages/home";

Modal.setAppElement("#root");

function App() {
    return (
        <Router>
            <ScrollToTop>
                <Header />
                <Switch>
                    <Route path="/" exact component={HomePage} />
                    <Route
                        path="/applicants"
                        exact
                        component={ApplicantsPage}
                    />
                </Switch>
                <Footer />
            </ScrollToTop>
            <ToastContainer />
        </Router>
    );
}

export default App;
