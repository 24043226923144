import React from "react";
import logoWithNameWhiteImage from "./../../assets/svgs/logo-w-name-white.svg";

const Footer = () => {
    return (
        <footer className={"bg-fu-brown text-white py-4"}>
            <div className="flex flex-col gap-4 justify-center items-center">
                <div>
                    <img src={logoWithNameWhiteImage} alt="Fillerup White Logo" />
                </div>
                <div className="font-ABeeZeeItalic text-sm">© Copyright Fillerup {new Date().getFullYear()}. All Rights Reserved.</div>
            </div>
        </footer>
    );
};

export default Footer;
