import React, { useState } from "react";
import { Link } from "react-router-dom";
import logoImage from "./../../assets/svgs/logo.svg";
import { MenuIcon } from "@heroicons/react/solid";
const Header = () => {
    const [showMenu, setShowMenu] = useState(false);

    const menuItems = (
        <>
            <a
                href="./#forCustomers"
                onClick={() => {
                    setShowMenu(!showMenu);
                }}
            >
                Customers
            </a>
            <a
                href="./#forApplicants"
                onClick={() => {
                    setShowMenu(!showMenu);
                }}
            >
                Applicants
            </a>
            <a
                href="./#safety"
                onClick={() => {
                    setShowMenu(!showMenu);
                }}
            >
                Safety
            </a>
            <a
                href="./#about"
                onClick={() => {
                    setShowMenu(!showMenu);
                }}
            >
                About
            </a>
        </>
    );

    return (
        <div className="container relative">
            <header className={"flex justify-between items-center py-2 px-4 sm:px-0 "}>
                <div>
                    <Link to={"/"}>
                        <img src={logoImage} alt="Fillerup Logo" />
                    </Link>
                </div>
                <div className={"space-x-6 hidden md:block"}>{menuItems}</div>
                <div className="block md:hidden ">
                    <div>
                        <MenuIcon
                            className="w-6 cursor-pointer hover:text-fu-brown transition"
                            onClick={() => {
                                setShowMenu(!showMenu);
                            }}
                        ></MenuIcon>
                    </div>
                    <div
                        className={`absolute flex flex-col bg-white gap-4 left-0 right-0 z-10 py-4 px-4 shadow-2xl transition ${
                            !showMenu && "hidden"
                        }`}
                        style={{
                            top: 67,
                        }}
                    >
                        {menuItems}
                    </div>
                </div>
            </header>
        </div>
    );
};

export default Header;
