import React from "react";
import towerInProgressImage from "./../../assets/images/tower-in-progress.png";
import logoWithNameImage from "./../../assets/svgs/logo-w-name.svg";
import notesImage from "./../../assets/images/notes.png";
import hammerImage from "./../../assets/images/hammer.png";
import thumbsUpImage from "./../../assets/images/thumbs-up.png";
import jimmyChanImage from "./../../assets/images/jimmy-chan.png";
import CustomersCarousel from "../../core/components/CustomersCarousel";
import SafetyCarousel from "../../core/components/SafetyCarousel";
import { Link } from "react-router-dom";
const HomePage = () => {
    return (
        <div>
            <section
                className={"bg-cover bg-no-repeat bg-center relative"}
                style={{
                    backgroundImage: `url(${towerInProgressImage})`,
                    height: "calc(100vh - 67px)",
                }}
            >
                <div
                    className={
                        "bg-white bg-opacity-60 absolute w-full h-full left-0 top-0 grid place-content-center text-center "
                    }
                >
                    <div className="-mt-28">
                        <img
                            src={logoWithNameImage}
                            alt="Fillerup Logo"
                            className={"m-auto mb-8 w-2/4"}
                        />
                    </div>
                    <div className={"font-ABeeZeeItalic font-sm"}>
                        “We excel in creating synergy between customers and
                        employees by understanding our customers’ needs and
                        <br />
                        matching our employees’ skills to exceed our customers’
                        expectations.”
                    </div>
                </div>
            </section>
            <section id="forCustomers">
                <div className={"3xl:container"}>
                    <div className={"flex flex-col items-center md:flex-row"}>
                        <div
                            className={
                                "font-Gentium pt-8 w-full md:w-2/4 md:pt-0"
                            }
                        >
                            <div
                                className={
                                    "text-5xl px-10 md:px-20 pb-4 font-bold"
                                }
                            >
                                For Customers
                            </div>

                            <CustomersCarousel />
                        </div>
                        <div
                            className={
                                "bg-no-repeat bg-cover w-full bg-center md:w-2/4"
                            }
                            style={{
                                backgroundImage: `url(${notesImage})`,
                                height: 500,
                            }}
                        ></div>
                    </div>
                </div>
            </section>

            <section id="forApplicants">
                <div className={"3xl:container"}>
                    <div className="flex flex-col-reverse md:flex-row">
                        <div
                            className={
                                "w-full md:w-2/4 bg-no-repeat bg-cover bg-center"
                            }
                            style={{
                                backgroundImage: `url(${hammerImage})`,
                                height: 500,
                            }}
                        ></div>
                        <div className="w-full md:w-2/4  grid place-content-center p-10 md:p-20 space-y-4">
                            <div className={"text-5xl font-Gentium font-bold"}>
                                For Applicants
                            </div>
                            <div className="font-ABeeZeeItalic font-sm">
                                If you are dependable, teachable,
                                self-motivated, a team player and willing to
                                give your all while on the job, positions are
                                available now: Rough Carpenter, Form Carpenter,
                                Carpenter Apprentice, Framer, Finish Carpenter,
                                Painter, Painter Apprentice, General Laborer,
                                Flagger, Forklift Operator, Heavy Equipment
                                Operator, Final Cleanup
                            </div>
                            <div>
                                <Link
                                    to={"/applicants"}
                                    className="bg-fu-brown text-white  py-2 px-4 rounded transition hover:opacity-80"
                                >
                                    Apply Now
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="safety">
                <div className={"3xl:container"}>
                    <div className={"flex flex-col md:flex-row items-center"}>
                        <div className={" font-Gentium w-full md:w-2/4 "}>
                            <div
                                className={
                                    "text-5xl px-10 md:px-20 pb-4 pt-8 md:pt-0 font-bold"
                                }
                            >
                                Safety
                            </div>
                            <SafetyCarousel />
                            <div className="text-center font-ABeeZeeItalic w-full md:w-3/5 px-10 md:px-0 m-auto py-8 md:py-4 font-sm">
                                We are committed to a proactive safety culture.
                                Ongoing In-House Training and Certification
                                Classes are a necessary part of our commitment
                                to safety.
                            </div>
                        </div>
                        <div
                            className={
                                "w-full md:w-2/4 bg-no-repeat bg-cover bg-center"
                            }
                            style={{
                                backgroundImage: `url(${jimmyChanImage})`,
                                height: 500,
                            }}
                        ></div>
                    </div>
                </div>
            </section>

            <section id="about">
                <div className={"3xl:container"}>
                    <div className="flex flex-col-reverse md:flex-row">
                        <div
                            className={"w-full md:w-2/4 bg-no-repeat bg-cover"}
                            style={{
                                backgroundImage: `url(${thumbsUpImage})`,
                                height: 500,
                            }}
                        ></div>
                        <div className="w-full md:w-2/4  grid place-content-center p-10 md:p-20 space-y-4">
                            <div className={"text-5xl font-Gentium font-bold"}>
                                About Us
                            </div>
                            <div className="font-ABeeZeeItalic font-sm">
                                Since 1996, for more than 25 years, we have
                                served the largest commercial construction
                                companies in the State of Utah.
                            </div>
                            <p>
                                Our address: 7410 S. Creek Road, STE 301, Sandy
                                Utah, 84093
                            </p>
                            <p>Our phone number: 801-463-2885</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default HomePage;
