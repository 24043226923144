import React, { FC, useEffect, useState } from "react";
import { IJob } from "../../../core/interfaces/job.interface";
import ApplicantForm from "./ApplicantForm";
import JobCard from "./JobCard";
import JobDetail from "./JobDetail";

interface IProps {
    jobs: IJob[];
}

const HiringNow: FC<IProps> = (props) => {
    const { jobs } = props;
    const [jobSelected, setJobSelected] = useState<IJob>();
    const [openModal, setOpenModal] = useState(false);

    const handleApply = (job: IJob) => {
        setOpenModal(true);
    };

    useEffect(() => {
        if (jobs && jobs.length) {
            setJobSelected(jobs[0]);
        }
    }, [jobs]);

    return (
        <div className="grid grid-cols-1 md:grid-cols-2  gap-5">
            {jobs?.length ? (
                <>
                    <div>
                        {jobs
                            .filter((job) => job.hiring)
                            .map((job) => (
                                <JobCard
                                    key={job.id}
                                    job={job}
                                    jobSelected={jobSelected}
                                    onClick={() => setJobSelected(job)}
                                    onApply={() => handleApply(job)}
                                />
                            ))}
                    </div>

                    <JobDetail job={jobSelected} />

                    <ApplicantForm
                        job={jobSelected}
                        openModal={openModal}
                        closeModal={() => setOpenModal(false)}
                    />
                </>
            ) : (
                <div>
                    We are currently not hiring for any positions, please check
                    back daily, as this changes frequently.
                </div>
            )}
        </div>
    );
};

export default HiringNow;
