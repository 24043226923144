import { useMutation } from "@apollo/client";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { FC, useEffect, useRef } from "react";
import Modal from "react-modal";
import MaskedInput from "react-text-mask";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { CREATE_APPLICANT } from "../../../core/graphql/applicants.graphql";
import { IJob } from "../../../core/interfaces/job.interface";
interface IProps {
    openModal: boolean;
    job: IJob | undefined;
    closeModal: () => void;
}

const phoneNumberMask = [
    "(",
    /[1-9]/,
    /\d/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
];

const ApplicationSchema = Yup.object().shape({
    firstname: Yup.string().required("Required."),
    mi: Yup.string().max(1, "Must be at most 1 character."),
    lastname: Yup.string().required("Required."),
    phone: Yup.string()
        .required("Required.")
        .test("len", "Invalid number", (val) => {
            if (val) {
                return val.toString().length === 14;
            }

            return false;
        }),
    email: Yup.string().email("Invalid email provided.").required("Required."),
    previousEmployerName: Yup.string(),
    previousEmployerPhone: Yup.string().test("len", "Invalid number", (val) => {
        if (val) {
            return val.toString().length === 14;
        }

        return true;
    }),
    workExperience: Yup.string(),
    atLeast18: Yup.bool().oneOf([true], "Field must be checked"),
});

const ApplicantForm: FC<IProps> = (props) => {
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const { openModal, job, closeModal } = props;
    // GRAPHQL
    const [createApplicant, { data, loading, error }] =
        useMutation(CREATE_APPLICANT);

    const onSubmit = (values: any, { resetForm }: any) => {
        const regex = /[()-] */g;

        const finalBody = {
            ...values,
            phone: values.phone?.replace(regex, ""),
            previousEmployerPhone: values.previousEmployerPhone?.replace(
                regex,
                ""
            ),
        };

        if (job) {
            createApplicant({
                variables: {
                    input: { ...finalBody, job: job.id },
                },
            });
        }
    };

    useEffect(() => {
        if (data) {
            toast.success("Applied successfully.");
            closeModal();
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            toast.error("Error applying, please try later.");
        }
    }, [error]);

    const customStyles: Modal.Styles = {
        content: {
            padding: 0,
            width: windowSize.current[0] > 800 ? "75%" : "auto",
            margin: "auto",
            backgroundColor: "transparent",
            border: "none",
        },
        overlay: {
            zIndex: 10001,
            overflowY: "auto",
            width: "100%",
        },
    };

    return (
        <Modal
            isOpen={openModal}
            style={customStyles}
            onRequestClose={closeModal}
            contentLabel="Modal"
        >
            <section className="">
                <Formik
                    initialValues={{
                        firstname: "",
                        mi: "",
                        lastname: "",
                        phone: "",
                        email: "",
                        previousEmployerName: "",
                        previousEmployerPhone: "",
                        workExperience: "",
                        meetMinimumRequired: false,
                        atLeast18: false,
                    }}
                    onSubmit={onSubmit}
                    validationSchema={ApplicationSchema}
                >
                    <Form>
                        <div className="bg-fu-dark-gray w-full md:w-4/4 mx-auto text-white p-10">
                            <div className="text-xl font-Gentium uppercase  tracking-wide">
                                Application Form
                            </div>

                            <div className="border-b border-white my-4"></div>

                            <div className="text-m flex flex-col md:flex-row gap-8 md:gap-16">
                                <div className="space-y-8 w-full md:w-2/4">
                                    <div className="flex flex-col md:flex-row gap-5">
                                        <div>
                                            <Field
                                                name="firstname"
                                                className={"form-input"}
                                                placeholder="First Name"
                                            />
                                            <ErrorMessage
                                                name="firstname"
                                                component="div"
                                                className={
                                                    "text-sm text-red-700 pl-4"
                                                }
                                            />
                                        </div>
                                        <div>
                                            <Field
                                                name="mi"
                                                className={"form-input"}
                                                placeholder="Middle Initial"
                                            />
                                            <ErrorMessage
                                                name="mi"
                                                component="div"
                                                className={
                                                    "text-sm text-red-700 pl-4"
                                                }
                                            />
                                        </div>
                                        <div>
                                            <Field
                                                name="lastname"
                                                className={"form-input"}
                                                placeholder="Last Name"
                                            />
                                            <ErrorMessage
                                                name="lastname"
                                                component="div"
                                                className={
                                                    "text-sm text-red-700 pl-4"
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-col md:flex-row gap-5">
                                        <div className="w-full md:w-3/6">
                                            <Field
                                                name="email"
                                                className={"form-input"}
                                                placeholder="Email"
                                            />
                                            <ErrorMessage
                                                name="email"
                                                component="div"
                                                className={
                                                    "text-sm text-red-700 pl-4"
                                                }
                                            />
                                        </div>
                                        <div className="w-full md:w-3/6">
                                            <Field
                                                name="phone"
                                                render={({ field }: any) => (
                                                    <MaskedInput
                                                        {...field}
                                                        mask={phoneNumberMask}
                                                        id="phone"
                                                        placeholder="Phone #"
                                                        type="tel"
                                                        className={"form-input"}
                                                        guide={false}
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                name="phone"
                                                component="div"
                                                className={
                                                    "text-sm text-red-700 pl-4"
                                                }
                                            />
                                            <small className="pl-4 italic font-thin">
                                                Format: (999) 999-9999
                                            </small>
                                        </div>
                                    </div>

                                    <div className="flex flex-col md:flex-row gap-5">
                                        <div className="w-full md:w-3/6">
                                            <Field
                                                name="previousEmployerName"
                                                className={"form-input"}
                                                placeholder="Previous Employer Name"
                                            />
                                            <ErrorMessage
                                                name="previousEmployerName"
                                                component="div"
                                                className={
                                                    "text-sm text-red-700 pl-4"
                                                }
                                            />
                                        </div>
                                        <div className="w-full md:w-3/6">
                                            <Field
                                                name="previousEmployerPhone"
                                                render={({ field }: any) => (
                                                    <MaskedInput
                                                        {...field}
                                                        mask={phoneNumberMask}
                                                        id="phone2"
                                                        placeholder="Phone #"
                                                        type="tel"
                                                        className={"form-input"}
                                                        guide={false}
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                name="previousEmployerPhone"
                                                component="div"
                                                className={
                                                    "text-sm text-red-700 pl-4"
                                                }
                                            />
                                            <small className="pl-4 italic font-thin">
                                                Format: (999) 999-9999
                                            </small>
                                        </div>
                                    </div>
                                    <div className="flex flex-col md:flex-row gap-5">
                                        <div className="w-full">
                                            <label className="flex">
                                                <Field
                                                    type="checkbox"
                                                    name="meetMinimumRequired"
                                                />
                                                <span className="ml-2">
                                                    I meet the required minimum
                                                    for this job posting
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-5">
                                        <div className="w-full">
                                            <label className="flex">
                                                <Field
                                                    type="checkbox"
                                                    name="atLeast18"
                                                />
                                                <span className="ml-2">
                                                    Are you at least 18 years of
                                                    age?
                                                </span>
                                            </label>
                                        </div>
                                        <ErrorMessage
                                            name="atLeast18"
                                            component="div"
                                            className={
                                                "text-sm text-red-700 pl-4"
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="space-y-4 w-full md:w-2/4">
                                    <div>
                                        <div className="font-thin tracking-wide">
                                            WORK EXPERIENCE
                                        </div>
                                        <Field
                                            name="workExperience"
                                            className={
                                                "w-full bg-gray-300 focus:outline-none p-2"
                                            }
                                            as="textarea"
                                            rows={5}
                                        />
                                        <ErrorMessage
                                            name="workExperience"
                                            component="div"
                                            className={"text-sm text-red-700 "}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-center  my-10">
                                <button
                                    className="rounded-xl bg-white py-1 px-14 text-fu-dark-gray transition hover:opacity-80 shadow-2xl"
                                    type="submit"
                                    disabled={loading}
                                >
                                    SUBMIT
                                </button>
                            </div>
                        </div>
                    </Form>
                </Formik>
            </section>
        </Modal>
    );
};

export default ApplicantForm;
