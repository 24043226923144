import Glide from "@glidejs/glide";
import React, { useEffect } from "react";

const SafetyCarousel = () => {
    useEffect(() => {
        new Glide(".glideSafety", {
            type: "carousel",
            autoplay: 5000,
        }).mount();
    }, []);

    return (
        <div className={"bg-fu-gray relative"}>
            <div
                className="glideSafety"
                style={{
                    minHeight: 230,
                }}
            >
                <div className="glide__track" data-glide-el="track">
                    <ul className="glide__slides">
                        <li className="glide__slide">
                            <div className={"flex px-10 md:px-20 py-6 gap-4 items-center"}>
                                <div className={"text-white text-center"}>
                                    <div className={"font-ABeeZee italic"}>
                                        "Fillerup Employment is a great company to work with. They have a solid safety program that takes a proactive
                                        approach. From site safety visits to regular training, when compared to other companies, they do a lot more to
                                        keep their employees safe."
                                    </div>
                                    <div className={"font-Abel uppercase tracking-wide"}>
                                        James Day
                                        <br /> Workers Compensation Fund
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="glide__slide">
                            <div className={"flex px-10 md:px-20 py-6 gap-4 items-center"}>
                                <div className={"text-white text-center"}>
                                    <div className={"font-ABeeZee italic"}>
                                        “Worked with Fillerup for a lot of years found them to be very responsive to my needs and proactive. They have
                                        one of the best safety cultures any employment agency that I ever worked with have. Fillerup goes out of their
                                        way to develop people not just workers.”
                                    </div>
                                    <div className={"font-Abel uppercase tracking-wide"}>
                                        Steve Santella
                                        <br /> Hughes Construction
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

                <div className="glide__bullets" data-glide-el="controls[nav]">
                    <button className="glide__bullet" data-glide-dir="=0"></button>
                    <button className="glide__bullet" data-glide-dir="=1"></button>
                </div>
            </div>
        </div>
    );
};

export default SafetyCarousel;
